import React from 'react';

// import skills data
import { skills } from '../data';

const Skills = () => {
  return (
    <section id='skills' className='section bg-[#f8f3eca1] pt-12 px-12'>
      <div className='container mx-auto text-center'>
        <h2 className='text-3xl md:text-5xl font-primary font-extrabold mb-10'>
          Çalışma Alanlarımız
        </h2>
        <div className={`lg:grid lg:grid-cols-auto gap-12 grid-cols-2 items-start `}>
          {skills.map((item, index) => {
            const { icon, title, description } = item;
            return (
              <div
                className={`flex flex-col items-center justify-center mb-16 last:mb-0 lg:mb-0 `}
                key={index}
              >
                <img className='mb-6 w-10 fill-black'  src={icon} alt='' />
                <h4 className='text-sm md:text-xl mb-2 font-primary font-bold'>
                  {title}
                </h4>
                <p className='text-sm md:text-lg px-2 '>{description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
