import React from 'react';

// import team data
import { team } from '../data';

const Team = () => {
  return (
    <section id='team' >
      <div className='container mx-auto text-center pt-12'>
        <h2 className='text-3xl md:text-5xl font-primary font-extrabold mb-4'>
          Ekibimiz
        </h2>

        {/* Grid Layout */}
        <div className='grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-10'>
          {team.map((item, index) => {
            const { image, name, position} = item;
            return (
              <div className={`text-center ${index === 4 ? 'lg:col-span-2' : ''}`} key={index}>  
                <img className='max-h-56 mx-auto mb-4 lg:max-h-[300px]' src={image} alt={name} />
                <h4 className='text-lg md:text-3xl mb-2 font-primary font-bold'>{name}</h4>
                <p className='text-sm md:text-2xl tracking-[0.3px]  mb-4 opacity-[0.8]'>
                  {position}
                </p>
               
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
