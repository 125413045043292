import React from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/whitelogo.png';

// import social
import { social } from '../data';

const Contact = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Email template data
  const template = {
    to_name: "Mehmet Şahin",
    from_name: username,
    message: message,
    phone: null,
    email: email,
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_vh153ji', // Service ID
        'template_rwfu0ei', // Template ID
        template, // Template parameters
        '2MATJ_yn9x3apQD2H' // Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Email gönderildi');
          setUsername('');
          setEmail('');
          setMessage('');
        },
        (error) => {
          console.log(error.text);
          alert('Bir hata oluştu');
        }
      );
  };

  return (
    <section
      id='contact'
      className='bg-primary text-white min-h-[732px] pt-12 pb-6 flex flex-col gap-10 lg:grid lg:grid-cols-2 px-10 justify-center items-center'
    >
      <div className='flex flex-col justify-center items-center col-span-2'>
        <h2 className='text-3xl md:text-5xl font-primary font-extrabold mb-4'>İletişim</h2>
        <p className='max-w-[540px] mx-auto px-6 lg:px-0 text-sm md:text-3xl'>Bizimle İletişime geçin.</p>
      </div>

      <div className='container text-center pt-12'>
        {/* form */}
        <form onSubmit={sendEmail} className='mx-auto flex flex-col space-y-6 mb-8'>
          <input
            className='form-control'
            type='text'
            placeholder='Ad Soyad'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className='form-control'
            type='text'
            placeholder='Email adresi'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            className='resize-none w-full h-[132px] outline-none rounded-sm p-4 font-body text-sm text-gray focus:ring-1 focus:ring-primary'
            placeholder='Mesajınız'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button className='btn bg-accent hover:bg-accent-hover transition-all'>Mesaj Gönder</button>
        </form>

        {/* social */}
        <div className='flex items-center justify-between mx-auto max-w-[205px]'>
          {social.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => (window.location.href = item.link)}
                style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
              >
                <img src={item.icon} alt={`social-icon-${index}`} />
              </button>
            );
          })}
        </div>
      </div>

      <div className='flex flex-col justify-start items-center'>
        <button
          onClick={() => (window.location.href = '/')}
          className='px-10 pb-10 mb-4'
          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
        >
          <img src={Logo} alt='logo' className='h-16 md:h-20' />
        </button>

        {/* Responsive Map */}
        <div className='w-full flex justify-center items-center'>
          <iframe
            title='office'
            className='border-0 rounded-md w-full max-w-[600px] h-[300px] md:h-[340px]'
            src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=ata%C3%A7%201%20sokak%205/6+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
            allowFullScreen={true}
            loading='lazy'
            style={{ border: '0' }}
          ></iframe>
        </div>

        <p className='px-10 text-sm md:text-lg mt-4'>
          <Link to='https://www.google.com/maps?ll=39.925445,32.857984&z=14&t=m&hl=en&gl=US&mapclient=embed&q=Ata%C3%A7-1+Sk.+No:5+Sa%C4%9Fl%C4%B1k+06430+%C3%87ankaya/Ankara+T%C3%BCrkiye'>
            Adres: <span className='underline'>Ataç-1 Sokak 5/6 Kızılay Ankara</span>
          </Link>
        </p>
        <p className='px-10 text-sm md:text-lg'>Telefon: 0312 435 68 86</p>
      </div>
    </section>
  );
};

export default Contact;
