// import profile images

// import about icons
import Skill1 from "../src/assets/img/skills/skill1.png";
import Skill2 from "../src/assets/img/skills/skill2.png";
import Skill3 from "../src/assets/img/skills/skill3.png";
import Skill4 from "../src/assets/img/skills/skill4.png";
import Skill5 from "../src/assets/img/skills/skill5.png";
import Skill6 from "../src/assets/img/skills/skill6.png";
import Skill7 from "../src/assets/img/skills/skill5.png";
import Skill8 from "../src/assets/img/skills/skill2.png";

// import team images
import TeamMember1 from "../src/assets/img/ulkukocturk.jpeg";
import TeamMember2 from "../src/assets/img/mehmetsahin.jpeg";
import TeamMember3 from "../src/assets/img/aysegulbulutsahin.jpeg";
import TeamMember4 from "../src/assets/img/beyzaarslan.jpeg";
import TeamMember5 from "../src/assets/img/arzudenizkir.jpeg";

// import social icons
import FacebookIcon from "../src/assets/img/social/facebook.png";
import TwitterIcon from "../src/assets/img/social/twitter.png";
import InstagramIcon from "../src/assets/img/social/instagram.png";
import YoutubeIcon from "../src/assets/img/social/youtube.png";
import PinterestIcon from "../src/assets/img/social/pinterest.png";

// navigation data
export const navigation = [
  {
    name: "Hakkımızda",
    href: "home",
  },
  {
    name: "Çalışma Alanlarımız",
    href: "skills",
  },
  {
    name: "Ekibimiz",
    href: "team",
  },
  {
    name: "İletişim",
    href: "contact",
  },
];

// testimonials data
export const testimonials = [
  {
    text: `Hukuk, adaletin terazisidir.`,
    author: "Bülent Tanör",
    href: `https://tr.wikipedia.org/wiki/B%C3%BClent_Tan%C3%B6r`,
  },
  {
    text: `Adaletin olmadığı bir yerde, hukukun da bir anlamı yoktur.`,
    author: "Martin_Luther_King_Jr",
    href: "https://tr.wikipedia.org/wiki/Martin_Luther_King_Jr.",
  },
  {
    text: `Hukuk, güçlülerin değil, adaletin ve hakların tarafında olmalıdır.`,
    author: "Thomas Jefferson",
    href: "https://tr.wikipedia.org/wiki/Thomas_Jefferson",
  },
  {
    text: `Bir avukat, hak arayışında yalnız değildir; adaletin yanında durur.`,
    author: "Thomas Jefferson",
    href: "https://tr.wikipedia.org/wiki/Thomas_Jefferson",
  },
  {
    text: `Avukatlar tarih boyu köle kullanmadılar ama hiçbir zaman efendileri de olmadı!`,
    author: "Molière",
    href: "https://tr.wikipedia.org/wiki/Moli%C3%A8re",
  },
  {
    text: `Hukukun üstünlüğü, bireylerin özgürlüğünü güvence altına alır; avukatlar, bu özgürlüğün teminatıdır.`,
    author: "Hannah Arendt",
    href: "https://tr.wikipedia.org/wiki/Hannah_Arendt",
  },
  {
    text: `Her şey kanun yapmaktan ibaret değildir. Aksine her şey o kanunları uygulamak ve uygulattırmaktan ibarettir. Uygulayan, yerine getiren, daima karar verenden daha kuvvetlidir.`,
    author : "Mustafa Kemal Atatürk",
    href: "https://tr.wikipedia.org/wiki/Mustafa_Kemal_Atat%C3%BCrk",
  },
  {
    text: `“Adalet kutup yıldızı gibi yerinde durur ve geri kalan herşey onun etrafında döner.”`,
    author: "Konfüçyüs",
    href: "https://tr.wikipedia.org/wiki/Konf%C3%BC%C3%A7y%C3%BCs",
  },
  {
    text: `Hiç kimse onu bulandırmadığı ve ihlal etmediği sürece hukuk, teneffüs ettiğimiz hava gibi görünmez ve tutulmaz bir şekilde etrafımızı kaplar. Hukuk, ancak kaybettiğimizi anladığımız zaman değerinin farkına vardığımız, sağlık gibi sezilmez bir şeydir.`,
    author: " Pierre Calamanderi",
    href: "https://tr.wikipedia.org/wiki/Pierre_Calamandrei",
  },
];

// skills data
export const skills = [
  {
    icon: Skill8,
    title: "Danışmanlık",
    description:
      "Hukuki süreçlerde yol gösterici olarak, müvekkillerimize profesyonel danışmanlık hizmetleri veriyoruz.",
  },
  {
    icon: Skill5,
    title: "Arabuluculuk",
    description:
      "Hukuki uyuşmazlıkların çözümünde hızlı ve etkin bir yöntem olan arabuluculuk hizmeti sunuyoruz.",
  },
  {
    icon: Skill1,
    title: "Ceza Hukuku",
    description:
      "Şikayet ve suç duyurusu başvurularının yapılması, soruşturma işlemlerinin takibi, kovuşturma aşamasında sanık müdafii ve müşteki(katılan) vekili sıfatıyla temsil hizmeti sunuyoruz.",
  },
  {
    icon: Skill2,
    title: "İş ve Sosyal Güvenlik Hukuku",
    description:
      "İşçi ve işveren arasındaki uyuşmazlıklar kapsamında iş kazasından doğan tazminat davaları, işçilik alacakları, işe iade, sigortasız çalıştırılan işçiler için hizmet tespiti, sigorta başlangıcının tespiti davalarının takibi; bireysel ve toplu iş sözleşmelerinin düzenlenmesi, iş sözleşmesinin devamı ve sonlandırılması konularında hukuki danışmanlık sunuyoruz.",
  },
  {
    icon: Skill3,
    title: "Gayrimenkul Hukuku",
    description:
      " Gayrimenkul alım satımı, kiralama ve projelendirme süreçlerinin yanı sıra; tapu iptali ve tescili, el atmanın önlenmesi, haksız işgale dayalı tazminat davalarına ve kat karşılığı inşaat sözleşmelerine ilişkin danışmanlık ve takip hizmetleri sunuyoruz.",
  },
  {
    icon: Skill3,
    title: "Kat Mülkiyeti Hukuku",
    description:
      "Kat mülkiyeti ve kat irtifakı konularında hukuki destek sunuyoruz. Apartman ve site yönetimiyle ilgili uyuşmazlıklarda çözüm odaklı danışmanlık sağlıyoruz.",
  },
  {
    icon: Skill3,
    title: "Kooperatifler Hukuku",
    description:
      "Kooperatiflerin kuruluşu, yönetimi ve işleyişiyle ilgili hukuki danışmanlık ve uyuşmazlık çözüm hizmetleri sağlıyoruz.",
  },
  {
    icon: Skill4,
    title: "Şirketler Hukuku",
    description:
      "Şirket kuruluşu, birleşme ve devralma işlemleri gibi ticari faaliyetlerde hukuki destek sağlıyoruz.",
  },
  
  {
    icon: Skill6,
    title: "Tüketici Hukuku",
    description:
      "Tüketici haklarının korunması, ayıplı mal ve hizmetler konularında danışmanlık ve dava süreçlerinde destek veriyoruz.",
  },
  {
    icon: Skill1,
    title: "Sağlık Hukuku",
    description:
      "Sağlık sektöründe çalışan hekimlerin mesleki faaliyetleri nedeniyle yaşadığı sorunlar ve hastaların tedavi uygulamaları nedeniyle yaşadığı sorunlara ilişkin danışmanlık hizmeti sunuyoruz.",
  },
  {
    icon: Skill7,
    title: "Aile Hukuku",
    description:
    "Boşanma, velayet, nafaka, ziynet eşyalarının iadesi ve mal rejiminin tasfiyesi, soybağı konularına ilişkin uyuşmazlıklarda danışmanlık ve dava takip hizmeti sunuyoruz.",
  },
  {
    icon: Skill2,
    title: "Kişisel Verilerin Korunması Hukuku",
    description:
      "Kişisel verilerin ihlali nedeniyle adli ve idari başvuruların yapılması, kişisel veri envanterlerinin hukuki analizi, aydınlatma ve açık rıza metinlerinin oluşturulması, veri aktarımına ilişkin gizlilik sözleşmelerinin hazırlanması, sözleşmelerin KVKK çerçevesinde revize edilmesi konularında danışmanlık hizmeti sunuyoruz.",
  },
];

// team data
export const team = [
  {
    image: TeamMember1,
    name: "Ülkü Koçtürk",
    position: "Kurucu Avukat",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember2,
    name: "Mehmet Şahin",
    position: "Kurucu Avukat",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember3,
    name: "Ayşegül Bulut Şahin",
    position: "Avukat",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember4,
    name: "Beyza Arslan",
    position: "Avukat",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember5,
    name: "Arzu Deniz Kır",
    position: "Ofis Koordinatörü",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
];

// social data
export const social = [
  {
    icon: FacebookIcon,
  },
  {
    icon: TwitterIcon,
  },
  {
    icon: InstagramIcon,
  },
  {
    icon: YoutubeIcon,
  },
  {
    icon: PinterestIcon,
  },
];
