import React from "react";

import HeroImage from "../assets/img/AdobeStock_241496951.jpeg";

// Import components
import Header from "./Header";

const Banner = () => {
  return (
    <section
      id="home"
      className="relative h-[300px] lg:h-[600px] overflow-hidden bg-gradient-to-t from-[#f8f3ec] to-[#e0c8ac]"
      style={{
        backgroundImage: `url(${HeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Header Component */}
      <Header />
    </section>
  );
};

export default Banner;
