import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Subscribe = () => {

  const [email, setEmail] = useState('');

  // Email template data
  const template = {
    to_name: "Mehmet Şahin",
    from_name: email,

  };

  const sendEmail = (e) => {
    e.preventDefault();

   
    emailjs
      .send(
        'service_vh153ji', // Service ID
        'template_jufuweb', // Template ID
        template, // Template parameters
        '2MATJ_yn9x3apQD2H' // Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Talebiniz alındı');
          setEmail('');
          
        },
        (error) => {
          console.log(error.text);
          alert('Bir hata oluştu');
        }
      );
  };


  return (
    <section className='bg-accent pt-12 md:pt-[54px] pb-[74px] min-h-[300px]'>
      <div className='container mx-auto px-8 text-center'>
        <h3 className='font-primary font-extrabold leading-[1.2] text-white text-3xl md:text-5xl mb-[12px]'>
          Bültenimize Abone Ol
        </h3>
       <p className='mb-[40px] text-white text-sm md:text-2xl' >
    Gelişmelerden haberdar olmak için bültenimize abone olun.
       </p>
        <form onSubmit={sendEmail} className='max-w-[600px] mx-auto flex flex-col gap-2 lg:flex-row '>
          <input
            className='form-control'
            type='text'
            placeholder='Email Adresiniz'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className='btn bg-primary hover:bg-primary-hover lg:max-w-[150px] lg:ml-4 ' type="submit">
            Katıl
          </button>
        </form>
      </div>
    </section>
  );
};

export default Subscribe;
