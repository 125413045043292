import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Appointment = () => {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Email template data
  const template = {
    to_name: "Mehmet Şahin",
    from_name: username,
    message: message,
    phone: phone,
    email: email,
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_vh153ji', // Service ID
        'template_rwfu0ei', // Template ID
        template, // Template parameters
        '2MATJ_yn9x3apQD2H' // Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Email gönderildi');
          setUsername('');
          setPhone('');
          setEmail('');
          setMessage('');
        },
        (error) => {
          console.log(error.text);
          alert('Bir hata oluştu');
        }
      );
  };

  return (
    <form onSubmit={sendEmail} className='bg-accent hidden w-full lg:flex flex-col px-8 rounded-sm md:max-w-md max-w-sm mx-auto 
    py-4 lg:absolute z-100 lg:-top-10 lg:left-20 '>
      <h3 className='text-white text-[28px] font-bold tracking-[0.3px] text-center'>
        Randevu Alın
      </h3>
      {/* Separator Bar */}
      <div className='w-[54px] h-[3px] bg-white my-6 mx-auto'></div>
      {/* Input Fields */}
      <div className='space-y-[24px]'>
        <input 
          className='form-control' 
          placeholder='Ad Soyad' 
          type='text' 
          value={username} 
          onChange={(e) => setUsername(e.target.value)}
          required
          style={{ boxSizing: 'border-box' }}
        />
        <input
          className='form-control'
          placeholder='Telefon numarası'
          type='tel'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          style={{ boxSizing: 'border-box' }}
        />
        <input
          className='form-control'
          placeholder='Email adresi'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{ boxSizing: 'border-box' }}
        />
        <textarea
          className='resize-none  outline-none rounded-sm p-4 font-body text-sm w-full'
          placeholder='Mesajınız'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          style={{ boxSizing: 'border-box' }}
        ></textarea>
        {/* Submit Button */}
        <button className='btn bg-primary hover:bg-primary-hover transition-all' type='submit'>
          Mesaj Gönder
        </button>
      </div>
    </form>
  );
};

export default Appointment;
