import React, { useEffect, useState } from 'react';

// Import components
import Nav from './Nav';
import NavMobile from './NavMobile';

// Import logo
import Logo from '../assets/img/logo_asd-removebg-preview.png';

const Header = () => {
  const [bg, setBg] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setBg(true);
      } else {
        setBg(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed w-full z-10 transition-all duration-300 ${
        bg ? 'bg-white shadow-md opacity-100' : 'bg-white opacity-60'
      }`}
      style={{ padding: '16px 0' }}
    >
      <div className='container mx-auto px-6 flex items-center justify-between'>
        {/* Logo */}
        <button
          onClick={() => window.location.href = '#'}
          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
        >
          <img src={Logo} alt='logo' className='h-10 md:h-20' />
        </button>
        {/* Navigation */}
        <Nav />
        {/* Mobile Navigation */}
        <NavMobile />
      </div>
    </header>
  );
};

export default Header;
